"use client";
import { Product } from "@models/models";
import React, { useState } from "react";
import ProductQuantitySelector from "./product-quantity-selector";
import ProductThumbnail from "./product-thumbnail";
import MoreFromCreatorLink from "@components/more-from-category-link";
import useCurrencyBasedCartRegionSwitcher from "@hooks/currency-based-cart-region-switcher";
import NoPricesForCountryMessage from "@components/no-prices-for-country-message";
import useAddToCart from "@hooks/add-to-cart";
import ProductDescription from "@components/product-description";
import useProductPageViewed from "@hooks/product-page-viewed";
import ProductDetailImageAndTitleMobile from "@components/product-detail-image-and-title-mobile";
import ProductDetailTitleDesktop from "@components/product-detail-title-desktop";
import ProductDetailContainer from "@components/product-detail-container";
import VariantPriceDisplay from "@components/variant-price-display";
import { FaSpinner } from "react-icons/fa";
import PageViewTracker from "@components/vendors/page-view-tracker";
import FeaturedProducts from "@components/featured-products";

export default function DigitalProductDetail({
  product,
}: {
  product: Product;
}) {
  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    product.brand.default_add_to_cart_quantity,
  );
  const {
    loadingAddToCart,
    addToCart,
    showAddToCartMessage,
    setShowAddToCartMessage,
    setAddToCartMessageRead,
  } = useAddToCart(product);

  useProductPageViewed(product);
  const { hasPricesForCartRegion } =
    useCurrencyBasedCartRegionSwitcher(product);

  if (product === undefined || hasPricesForCartRegion === undefined)
    return <></>;
  return (
    <ProductDetailContainer product={product}>
      <div className="lg:self-auto w-full lg:w-2/3">
        <ProductDetailImageAndTitleMobile product={product} />
        <ProductDetailTitleDesktop product={product} />

        <section aria-labelledby="information-heading" className="mt-4">
          <ProductDescription description={product.description} />
          <div className="mt-4">
            <section aria-labelledby="options-heading">
              {hasPricesForCartRegion ? (
                <form>
                  <div className={"w-full items-center"}>
                    <div
                      className={
                        "mt-4 flex flex-col items-center justify-center"
                      }
                    >
                      <div className={"font-bold p-2"}>
                        <VariantPriceDisplay variant={product.variants[0]} />
                      </div>
                      <div>
                        <ProductQuantitySelector
                          default_add_to_cart_quantity={
                            product.brand.default_add_to_cart_quantity
                          }
                          max_add_to_cart_quantity={
                            product.brand.max_add_to_cart_quantity
                          }
                          setSelectedQuantity={(quantity) =>
                            setSelectedQuantity(quantity)
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-4 mb-8">
                      {!loadingAddToCart && (
                        <div className={"flex flex-col items-center"}>
                          <button
                            onClick={() => {
                              console.log(
                                "in add to cart click handler ",
                                selectedQuantity,
                              );
                              addToCart(
                                "checkout",
                                product.variants[0].id,
                                selectedQuantity,
                                true,
                              );
                            }}
                            disabled={loadingAddToCart}
                            className="mb-3 btn btn-primary btn-block"
                            type={"button"}
                          >
                            Add to Cart & Checkout
                          </button>
                        </div>
                      )}
                      {loadingAddToCart && (
                        <div
                          className={"flex items-center place-content-center"}
                        >
                          <FaSpinner className={"fa-spin text-xl"} />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              ) : (
                <NoPricesForCountryMessage />
              )}
              <FeaturedProducts product={product} />
            </section>
          </div>
        </section>
      </div>

      <div className="mt-2 lg:mt-0 lg:col-start-2 lg:row-span-2 hidden lg:block lg:w-1/2">
        <div className="aspect-h-1 rounded-lg overflow-hidden">
          <ProductThumbnail product={product} />
          <div>
            <MoreFromCreatorLink product={product} />
          </div>
        </div>
      </div>
      {showAddToCartMessage && (
        <dialog className="modal modal-open">
          <div className="modal-box">
            <div className="flex flex-col items-center text-center">
              <p className="py-4 text-2xl">{product.add_to_cart_message}</p>

              <button
                className={"btn btn-primary"}
                onClick={() => {
                  setAddToCartMessageRead(true);
                  setShowAddToCartMessage(false);
                  addToCart(
                    "checkout",
                    product.variants[0].id,
                    selectedQuantity,
                    true,

                    {
                      skip_add_to_cart_message_check: true,
                    },
                  );
                }}
              >
                Acknowledge
              </button>
            </div>
          </div>
        </dialog>
      )}
      <PageViewTracker analyticsElements={product.account.analytics_elements} />
    </ProductDetailContainer>
  );
}
