import React from "react";
import { Venue } from "@models/models";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export function CityName({ venue }: { venue: Venue }) {
  const event_location_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.event_location_background_color,
    ),
  );
  const event_location_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.event_location_text_color),
  );

  if (!event_location_background_color || !event_location_text_color)
    return <></>;
  return (
    <div
      style={{
        backgroundColor: event_location_background_color,
        color: event_location_text_color,
      }}
      className="font-sans p-1 bg-accent uppercase text-sm text-center"
    >
      {venue.is_virtual ? (
        <span>Virtual Event</span>
      ) : (
        <span>
          {venue.city && (
            <>
              {venue.city.name}, {venue.province}
            </>
          )}
          {!venue.city && venue.province && (
            <>
              {venue.province}, {venue.country}
            </>
          )}
          {!venue.city && !venue.province && (
            <>
              {venue.country}
            </>
          )}
        </span>
      )}
    </div>
  );
}
