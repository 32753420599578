"use client";
import { CityName } from "./city-name";
import { Product, ProductVariant } from "@models/models";
import { Venue } from "./venue";
import { Participants } from "./participants";
import React, { useCallback, useEffect, useState } from "react";
import ProductVariantOptions from "./product-variant-options";
import ProductQuantitySelector from "./product-quantity-selector";
import ProductThumbnail from "./product-thumbnail";
import {
  firstVariantCanBeAddedToCart,
  formatAvailableDate,
  isAvailableForSale,
} from "@utils/event-display-helpers";
import dayjs from "dayjs";
import { Message } from "./message";
import Link from "next/link";
import NotifyWhenProductAvailableForm from "./notify-when-product-available-form";
import { FaSpinner } from "react-icons/fa";
import MoreFromCreatorLink from "@components/more-from-category-link";
import PageViewTracker from "@components/vendors/page-view-tracker";
import CustomerReview from "@components/customer-review";
import useCurrencyBasedCartRegionSwitcher from "@hooks/currency-based-cart-region-switcher";
import NoPricesForCountryMessage from "@components/no-prices-for-country-message";
import useAddToCart from "@hooks/add-to-cart";
import ProductDescription from "@components/product-description";
import useProductPageViewed from "@hooks/product-page-viewed";
import ProductDetailImageAndTitleMobile from "@components/product-detail-image-and-title-mobile";
import ProductDetailTitleDesktop from "@components/product-detail-title-desktop";
import ProductDetailContainer from "@components/product-detail-container";
import FeaturedProducts from "@components/featured-products";
import { useCart } from "@hooks/cart";
import { Cart } from "@medusajs/medusa";
import PreSaleCodeForm from "@components/pre-sale-code-form";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";
import QuickLinks from "@components/quick-links";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
export default function EventProductDetail({ product }: { product: Product }) {
  const [showAddToBag, setShowAddToBag] = useState<boolean | undefined>();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    product.brand.default_add_to_cart_quantity,
  );
  const [availableForSale, setIsAvailableForSale] = useState(false);
  const [thumbnail, setThumbnail] = useState(product.thumbnail);
  const cart = useCart();
  const {
    addToCart,
    loadingAddToCart,
    setPayWhatYouCanPrice,
    showAddToCartMessage,
    setAddToCartMessageRead,
    setShowAddToCartMessage,
    action,
  } = useAddToCart(product);

  useProductPageViewed(product);
  const { hasPricesForCartRegion } =
    useCurrencyBasedCartRegionSwitcher(product);

  const setVariantId = useCallback(
    (variantId: string) => {
      const variant = product.activeVariants.find((v) => v.id === variantId);
      setSelectedVariant(variant);
      if (!variant) return;
      const thumbnail = variant.thumbnail
        ? variant.thumbnail
        : product.thumbnail;
      setThumbnail(thumbnail);
      const hasInventory = product.activeVariants.find(
        (v) =>
          v.id === variantId &&
          (v.inventory_quantity != 0 || product.is_giftcard),
      );
      setShowAddToBag(hasInventory !== undefined);
    },
    [product],
  );

  useEffect(() => {
    if (product.activeVariants.length !== 0) {
      setVariantId(product.activeVariants[0].id as string);
    }
    setIsAvailableForSale(isAvailableForSale(product, cart as Cart));
    setShowAddToBag(firstVariantCanBeAddedToCart(product));
  }, [product, setVariantId, cart]);

  const add_to_cart_button_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.primary_button_background_color,
    ),
  );
  const add_to_cart_button_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.primary_button_text_color),
  );
  const product_detail_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.product_detail_text_color),
  );

  const show_event_location_with_thumbnail = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.show_event_location_with_thumbnail,
    ),
  );

  const show_add_to_cart_and_keep_shopping_button = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.show_add_to_cart_and_keep_shopping_button,
    ),
  );

  if (
    product === undefined ||
    hasPricesForCartRegion === undefined ||
    show_add_to_cart_and_keep_shopping_button === undefined ||
    !add_to_cart_button_background_color ||
    !add_to_cart_button_text_color ||
    !product_detail_text_color
  )
    return <></>;
  return (
    <ProductDetailContainer product={product}>
      <div className="lg:self-auto w-full lg:w-2/3">
        <ProductDetailImageAndTitleMobile
          product={product}
          thumbnail={thumbnail}
        />
        <ProductDetailTitleDesktop product={product} />
        <QuickLinks product_content_blocks={product.product_content_blocks} />

        {!product.is_giftcard && !availableForSale && (
          <div className={"mt-4 flex flex-col gap-y-4"}>
            <Message
              message={`This item is available for sale on ${formatAvailableDate(product)}`}
              type={"success"}
              title={"Availability"}
            />
            {product.pre_sale_code && (
              <PreSaleCodeForm
                product_id={product.id}
                pre_sale_code={product.pre_sale_code}
              />
            )}

            <NotifyWhenProductAvailableForm productId={product.id} />
          </div>
        )}

        <section aria-labelledby="information-heading" className="mt-4">
          {product.featured_reviews.length !== 0 && (
            <div className={"flex flex-col gap-y-2"}>
              {product.featured_reviews.map((featured_review) => (
                <CustomerReview
                  key={featured_review.id}
                  review={featured_review.review}
                  showProductTitle={false}
                  showInCard={true}
                />
              ))}
            </div>
          )}
          <ProductDescription description={product.description} />
          <div className="mt-4">
            <section aria-labelledby="options-heading">
              {hasPricesForCartRegion ? (
                <form>
                  <div className="sm:flex sm:justify-between">
                    <ProductVariantOptions
                      product={product}
                      onPayWhatYouCanPriceChange={(price) =>
                        setPayWhatYouCanPrice(price)
                      }
                      setVariantId={(variantId) => setVariantId(variantId)}
                    />
                  </div>
                  {availableForSale && (
                    <>
                      {product.externalOrderUrl ? (
                        <div className={"mt-10"}>
                          <Link
                            href={product.externalOrderUrl}
                            className={"mb-5 btn btn-secondary btn-block"}
                          >
                            Click Here to Purchase Tickets for This Event
                          </Link>
                        </div>
                      ) : (
                        <div className={"w-full items-center"}>
                          <div className={"mt-4"}>
                            <ProductQuantitySelector
                              default_add_to_cart_quantity={
                                product.brand.default_add_to_cart_quantity
                              }
                              max_add_to_cart_quantity={
                                product.brand.max_add_to_cart_quantity
                              }
                              setSelectedQuantity={(quantity) => {
                                console.log(
                                  "what quantity was selected in call back, ",
                                  quantity,
                                );
                                setSelectedQuantity(quantity);
                              }}
                            />
                          </div>
                          <div className="mt-4 mb-8">
                            {!loadingAddToCart && showAddToBag && (
                              <div className={"flex flex-col items-center"}>
                                <button
                                  onClick={() =>
                                    addToCart(
                                      "checkout",
                                      selectedVariant?.id as string,
                                      selectedQuantity,
                                      !selectedVariant?.seatingChart,
                                    )
                                  }
                                  disabled={loadingAddToCart}
                                  style={{
                                    backgroundColor:
                                      add_to_cart_button_background_color,
                                    color: add_to_cart_button_text_color,
                                    border: "none",
                                  }}
                                  className="mb-3 btn btn-primary btn-block"
                                  type={"button"}
                                >
                                  Add to Cart & Checkout
                                </button>
                                {show_add_to_cart_and_keep_shopping_button && (
                                  <button
                                    onClick={() =>
                                      addToCart(
                                        "keep-shopping",
                                        selectedVariant?.id as string,
                                        selectedQuantity,
                                        !selectedVariant?.seatingChart,
                                      )
                                    }
                                    disabled={loadingAddToCart}
                                    className="mb-3 link btn-sm"
                                    type={"button"}
                                  >
                                    Add to Cart and Keep Shopping
                                  </button>
                                )}
                              </div>
                            )}
                            {loadingAddToCart && (
                              <div
                                className={
                                  "flex items-center place-content-center"
                                }
                              >
                                <FaSpinner className={"fa-spin text-xl"} />
                              </div>
                            )}

                            {!showAddToBag && (
                              <button
                                disabled={true}
                                className="mb-5  btn ghost btn-block"
                              >
                                Add to Cart
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </form>
              ) : (
                <NoPricesForCountryMessage />
              )}

              <FeaturedProducts product={product} />
              <div className="mt-10">
                <Participants
                  variantParticipants={selectedVariant?.variantParticipants}
                />
              </div>
            </section>
          </div>
        </section>
      </div>

      {/* Product image */}
      <div className="mt-2 lg:mt-0 lg:col-start-2 lg:row-span-2">
        <div className="aspect-h-1 rounded-lg overflow-hidden">
          {!product.is_giftcard && show_event_location_with_thumbnail && (
            <CityName venue={product.venueArrangement.venue} />
          )}
          <ProductThumbnail thumbnail={thumbnail} product={product} />
          {!product.is_giftcard && (
            <div>
              <MoreFromCreatorLink product={product} />
              {!product.is_venue_hidden && (
                <Venue
                  show_map={product.brand.is_map_shown_on_event_page}
                  venue={product.venueArrangement.venue}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {showAddToCartMessage && (
        <dialog className="modal modal-open">
          <div className="modal-box">
            <div className="flex flex-col items-center text-center">
              <p className="py-4 text-2xl">{product.add_to_cart_message}</p>

              <button
                className={"btn btn-primary"}
                onClick={() => {
                  setAddToCartMessageRead(true);
                  setShowAddToCartMessage(false);
                  addToCart(
                    action,
                    selectedVariant?.id as string,
                    selectedQuantity,
                    !selectedVariant?.seatingChart,

                    {
                      skip_add_to_cart_message_check: true,
                    },
                  );
                }}
              >
                Acknowledge
              </button>
            </div>
          </div>
        </dialog>
      )}
      <PageViewTracker analyticsElements={product.account.analytics_elements} />
    </ProductDetailContainer>
  );
}
