import { isMobile } from "react-device-detect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";
import { scrollToTop } from "@utils/scroll-to-top";
const MOBILE_READ_MORE_WORD_COUNT_FOR_THRESHOLD_FOR = 40;
const DESKTOP_READ_MORE_WORD_COUNT_FOR_THRESHOLD_FOR = 300;

export default function ProductDescription({
  description,
}: {
  description: string | null;
}) {
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [descriptionCanExpand, setDescriptionCanExpand] = useState(
    description
      ? description.split(" ").length >
          (isMobile
            ? MOBILE_READ_MORE_WORD_COUNT_FOR_THRESHOLD_FOR
            : DESKTOP_READ_MORE_WORD_COUNT_FOR_THRESHOLD_FOR)
      : false,
  );
  const product_detail_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.product_detail_text_color),
  );

  if (!product_detail_text_color) return <></>;
  return (
    <>
      <h2 id="information-heading" className="sr-only">
        {description}
      </h2>
      <div
        className={`${descriptionCanExpand && !descriptionExpanded ? `${isMobile ? "h-52" : "h-60"}  text-opacity-gradient overflow-hidden` : ""} mt-4 space-y-6`}
      >
        <div
          style={{ whiteSpace: "pre-wrap", color: product_detail_text_color }}
          dangerouslySetInnerHTML={{
            __html: description as string,
          }}
        ></div>
      </div>
      {!descriptionExpanded && descriptionCanExpand && (
        <button
          className={"mt-2 text-center text-gray-400 text-sm w-full"}
          onClick={() => setDescriptionExpanded(true)}
        >
          Read More
        </button>
      )}
      {descriptionExpanded && descriptionCanExpand && (
        <button
          className={"mt-2 text-center text-gray-400 text-sm w-full"}
          onClick={() => {
            setDescriptionExpanded(false);
            scrollToTop();
          }}
        >
          View Less
        </button>
      )}
    </>
  );
}
